import React, { useEffect, useMemo, useState } from "react";
import { Button, IconButton, TextField } from "@mui/material";
import { useCollection } from "react-firebase-hooks/firestore";
import { addDoc, collection, deleteDoc, doc, getFirestore, query, setDoc, Timestamp, updateDoc, where } from "firebase/firestore";
import firebaseClient from "../../services/firebaseClient";
import { DataGrid, GridColDef, GridValidRowModel, GridValueGetterParams, gridVisibleSortedRowEntriesSelector } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from '@mui/icons-material/Info';
import AddEditComponent from "./AddEditComponent";
import { deleteUser, getAuth, User } from "firebase/auth";
import { tableTranslations } from "../../misc/localizations";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { TimeTracking } from "../DogodkiPage/NotificationComponent";
import firebaseService from "../../services/firebaseService";
import jsPDF from "jspdf";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CSVLink } from "react-csv";
import RestartAltIcon from "@mui/icons-material/RestartAlt";


const auth = getAuth(firebaseClient);

export default function TimeTrackingPage() {
	const [sortedRows, setSortedRows] = useState<GridValidRowModel[]>([]);

	// auth check
	const navigate = useNavigate();
	const [user, userLoading, userError] = useAuthState(auth);
	if (!user) navigate("/prijava");

	const [value, loading, error] = useCollection(collection(getFirestore(firebaseClient), "timetracking"));
	const [valConfirmedHours] = useCollection(query(collection(getFirestore(firebaseClient), "confirmed_hours")));

	const [buildSitesDocs] = useCollection(collection(getFirestore(firebaseClient), "buildsites"));

	// const [events] = useCollection(collection(getFirestore(firebaseClient), "events"));
	const [users] = useCollection(collection(getFirestore(firebaseClient), "profiles"));
	const [sendSmsEventId, setSendSmsEventId] = useState<null | string>(null);


	const [selectedFilterDate, setSelectedFilterDate] = useState<any | null>(null);

	const [addOrEditMode, setAddOrEditMode] = useState<"add" | "edit" | null>(null);
	const [editingObject, setEditingObject] = useState<TimeTracking | null>(null);

	const handleEditClick = (id: string) => {
		const found = rows.find((u) => u.id === id);

		if (found) {
			setEditingObject(found);
			setAddOrEditMode("edit");
		}
	};

	const rows: TimeTracking[] = useMemo(() => {
		if (!value || error) return [];

		let timeData = value?.docs.sort((a, b) => b.data().timestamp > a.data().timestamp ? 1 : -1).map((doc) => {
			const dataSms = doc.data();

			//get user data from id
			let user: any;
			users?.docs.map((doc) => {
				const dataUser = doc.data();
				if (dataUser.email == dataSms.email) {
					user = dataUser;
				}
			});

			// check if confirmed
			let confirmed = false;
			valConfirmedHours?.docs.map((confirmedDoc) => {
				const dataConfirmed = confirmedDoc.data();
				if (dataConfirmed.month.toDate().getMonth() == dataSms.timestamp.toDate().getMonth() && dataConfirmed.month.toDate().getFullYear() == dataSms.timestamp.toDate().getFullYear() && dataConfirmed.email == dataSms.email) {
					confirmed = true;
				}

			});

			// find buildsite name

			var foundSite = buildSitesDocs?.docs.find((buildsiteDoc) => buildsiteDoc.id == dataSms.buildsite_id);

			return {
				id: doc.id,
				name: user?.name ?? '/',
				email: dataSms.email,
				entry_type: dataSms.entry_type,
				entry_value: dataSms.entry_value,
				urna_postavka: user?.hourly_rate ? `${user?.hourly_rate}€` : '/',
				net_pay: user?.hourly_rate ? `${user?.hourly_rate * dataSms.entry_value}€` : '/',
				timestamp: dataSms.timestamp,
				confirmed: confirmed,
				buildsite_id: dataSms.buildsite_id,
				buildsite_name: foundSite?.data().name ?? '/',
			} as TimeTracking;
		});

		timeData = timeData.filter((dt) => {
			if (selectedFilterDate == null) return true;
			return dt.timestamp.toDate().getFullYear() == selectedFilterDate?.getFullYear() && dt.timestamp.toDate().getMonth() == selectedFilterDate?.getMonth();
		});

		return timeData;
	}, [value, selectedFilterDate]);

	const columns: GridColDef[] = [
		{ field: "id", headerName: "ID", minWidth: 110, flex: 1 },
		{ field: "name", headerName: "Uporabnik", minWidth: 100, flex: 1 },
		{ field: "email", headerName: "Email", minWidth: 150, flex: 1 },
		{
			field: "timestamp",
			headerName: "Datum",
			minWidth: 160,
			flex: 1,
			renderCell: (params: any) => {
				return <p>{params?.value?.toDate()?.toLocaleString() ?? "/"}</p>;
			},
		},
		{ field: "entry_value", headerName: "Število ur", minWidth: 50, flex: 1 },
		{ field: "urna_postavka", headerName: "Urna postavka", minWidth: 120, flex: 1 },
		{
			field: "net_pay", headerName: "Plača (neto)", minWidth: 100, flex: 1, renderCell: (params: any) => {
				return <p style={{ color: 'red' }}>{params?.value ?? "/"}</p>;
			},
		},
		{
			field: "entry_type",
			headerName: "Vrsta",
			minWidth: 120,
			flex: 1,
			renderCell: (params: any) => {
				return <p>{params?.value == "dopust" ? "Dopust" : params?.value == "praznik" ? "Praznik" : params?.value == "prost_dan" ? "Prosto" : params?.value == "working_day" ? "Delovni dan" : params?.value ?? ""}</p>;
			},
		},
		{
			field: "confirmed",
			headerName: "Potrjen",
			minWidth: 100,
			flex: 1,
			renderCell: (params: any) => {
				return <p>{params?.value ? 'DA' : 'NE'}</p>
			},
		},
		{ field: "buildsite_name", headerName: "Gradbisce", minWidth: 200, flex: 1 },
		{
			field: "options",
			headerName: "Moznosti",
			maxWidth: 180,
			flex: 1,
			renderCell: (params: any) => {
				return (
					<>
						<IconButton aria-label="info" size="small" onClick={() => HandleInfoClick(params?.row.email)}>
							<InfoIcon fontSize="inherit" />
						</IconButton>
						<IconButton aria-label="edit" size="small" onClick={() => handleEditClick(params.id)}>
							<EditIcon fontSize="inherit" />
						</IconButton>
						<IconButton aria-label="delete" size="small" onClick={() => handleDeleteClick(params.id)}>
							<DeleteIcon fontSize="inherit" />
						</IconButton>

					</>


				);
			},
		},
	];

	const sumHours = useMemo(() => rows?.reduce((prev: any, newVal: any) => prev + newVal.entry_value, 0) ?? 0, [rows]);

	const csvData = useMemo(() => {
		const headers = ["UPORABNIK", "EMAIL", "DATUM", "ŠT UR", "URNA POSTAVKA", "PLACA (NETO)", "VRSTA", 'POTRJEN', 'GRADBIŠČE'];

		const data = sortedRows.map((elt: any) => [
			elt.timestamp.toDate()?.toLocaleString(),
			elt.name,
			elt.email,
			elt.entry_value?.toString(),
			elt.urna_postavka,
			elt.net_pay,
			elt.entry_type == "dopust"
				? "Dopust"
				: elt.entry_type == "praznik"
					? "Praznik"
					: elt.entry_type == "prost_dan"
						? "Prosto"
						: elt.entry_type == "working_day"
							? "Delovni dan"
							: elt.entry_type,
			elt.confirmed ? 'DA' : 'NE',
			elt.buildsite_name,
		]);

		return [headers, ...data];
	}, [rows, sortedRows]);

	const exportPDF = () => {
		const unit = "pt";
		const size = "A4"; // Use A1, A2, A3 or A4
		const orientation = "portrait"; // portrait or landscape

		const marginLeft = 40;
		const doc = new jsPDF(orientation, unit, size);

		doc.setFontSize(15);

		const title = "Porocilo ur" + (selectedFilterDate ? " za " + selectedFilterDate?.toLocaleString("sl-SI", { month: "long" }) + " " + selectedFilterDate?.getFullYear() : "");

		const headers = [["DATUM", "IME", "EMAIL", "ŠT UR", "POSTAVKA", "PLACA (NETO)", "VRSTA", 'POTRJEN', 'GRADBIŠČE']];

		const data = sortedRows.map((elt: any) => [
			elt.timestamp.toDate()?.toLocaleString(),
			elt.name,
			elt.email,
			elt.entry_value,
			elt.urna_postavka,
			elt.net_pay,
			elt.entry_type == "dopust"
				? "Dopust"
				: elt.entry_type == "praznik"
					? "Praznik"
					: elt.entry_type == "prost_dan"
						? "Prosto"
						: elt.entry_type == "working_day"
							? "Delovni dan"
							: elt.entry_type,
			elt.confirmed ? 'DA' : 'NE',
			elt.buildsite_name,
		]);

		let content = {
			startY: 50,
			head: headers,
			body: data,
		};
		var sumString = "Skupno število ur: " + sumHours;

		doc.text(title, marginLeft, 40);
		// @ts-ignore
		doc.autoTable(content);

		// @ts-ignore
		let finalY = doc.autoTable.previous.finalY;
		doc.setFontSize(11);
		doc.text(sumString, marginLeft, finalY + 20);

		const filename = selectedFilterDate ? "belezenje_ur_" + (parseInt(selectedFilterDate?.getMonth()) + 1) + "_" + selectedFilterDate?.getFullYear() + '.pdf' : "belezenje_ur_skupno.pdf";
		doc.save(filename);
	};

	const HandleInfoClick = async (email: string) => {
		navigate('/timetrackinginfo', { state: { email: email } });
	};

	const handleDeleteClick = async (id: string) => {
		const shouldDelete = window.confirm("Ali ste prepričani, da želite izbrisati vpisane ure?");

		if (shouldDelete) {
			const res = await deleteDoc(doc(getFirestore(firebaseClient), "timetracking", id));
		}
	};

	// const addEventResponses = async (users: any[], event: any) => {
	// 	if (users?.length <= 0 || !event) return alert("At least one user and event is required");

	// 	users.forEach((usr) => {
	// 		const newDoc = {
	// 			event_id: event.id,
	// 			user_id: usr.id,
	// 			user_response: true,
	// 		};
	// 		firebaseService.addDocument("event_responses", newDoc);
	// 	});
	// };

	const updateEventResponse = async (eventRes: any) => {
		if (!eventRes?.id) {
			setAddOrEditMode(null);
			return;
		}
		console.log({ eventRes});
		const getTimetracking = doc(getFirestore(firebaseClient), "timetracking", eventRes.id!);

		const res = await updateDoc(getTimetracking, { ...eventRes });
		setAddOrEditMode(null);
	}

	return (
		<div className="h-[80vh] w-[1400px] max-w-full p-4 m-auto">
			<h1 className="mb-6 text-3xl">
				Beleženje ur
				{/* <IconButton aria-label="add" size="small" onClick={handleAddClick}>
					<AddIcon fontSize="inherit" />
				</IconButton> */}

				<div className="flex justify-between mb-4 items-end mt-2">
					<div className="flex items-center">
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DatePicker
								views={["month", "year"]}
								label="Filter"
								minDate={new Date("2012-03-01")}
								maxDate={new Date("2030-06-01")}
								value={selectedFilterDate}
								onChange={(val) => {
									setSelectedFilterDate(new Date(val?.toString() ?? ''))
								}}
								renderInput={(params: any) => (
									<TextField {...params} helperText={null} />
								)}
							/>
						</LocalizationProvider>
						<IconButton onClick={() => setSelectedFilterDate(null)}>
							<RestartAltIcon fontSize="inherit" />
						</IconButton>
					</div>

					<div className="flex gap-4 h-8">
						<Button variant="contained" color="info" onClick={exportPDF}>
							PDF
						</Button>
						<Button variant="contained" color="info">
							<CSVLink filename={selectedFilterDate ? ("belezenje_ur_" + (parseInt(selectedFilterDate?.getMonth()) + 1) + "_" + selectedFilterDate?.getFullYear() + '.csv') : "belezenje_ur_skupno.csv"} data={csvData}>CSV</CSVLink>
						</Button>
					</div>
				</div>
			</h1>
			{addOrEditMode && <AddEditComponent onSave={updateEventResponse} onClose={() => setAddOrEditMode(null)} mode={addOrEditMode} editingTimetracking={editingObject} />}
			{rows && <DataGrid localeText={tableTranslations} rows={rows} columns={columns} pageSize={15} rowsPerPageOptions={[5]} onStateChange={(state) => {
				const newRows = gridVisibleSortedRowEntriesSelector(state);
				const final = newRows.map((row) => row.model);
				setSortedRows(final);
			}} />}
		</div>
	);
}
