import { useMemo, useState } from "react";
import {
  IconButton,
} from "@mui/material";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import firebaseClient from "../../services/firebaseClient";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { getAuth } from "firebase/auth";
import { tableTranslations } from "../../misc/localizations";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import AddEditComponent from "./AddEditComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Foundation } from "@mui/icons-material";

const auth = getAuth(firebaseClient);

export default function MesecniDodatkiPage() {
  // auth check
  const navigate = useNavigate();
  const [user, userLoading, userError] = useAuthState(auth);
  if (!user) navigate("/prijava");

  const [categoriesDocs, categoriesLoading, categoriesErr] = useCollection(
    collection(getFirestore(firebaseClient), "user_categories")
  );
  const categories = categoriesDocs?.docs.map(cat => cat.data().name);


  const [value, loading, error] = useCollection(
    collection(getFirestore(firebaseClient), "monthly_pay_additions")
  );
  const [addOrEditMode, setAddOrEditMode] = useState<"add" | "edit" | null>(null);
  const [editingAddition, setEditingAddition] = useState<MonthlyAddition | null>(null);

  // these are users stored in firestore, not from firebase auth
  const fbUsers: MonthlyAddition[] = useMemo(() => {
    if (!value || error) return [];

    return value?.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        name: data.name,
        user: data.user,
        amount: data.amount,
      } as MonthlyAddition;
    });
  }, [value]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", minWidth: 220, flex: 1 },
    { field: "name", headerName: "Ime", minWidth: 150, flex: 1 },
    { field: "user", headerName: "Uporabnik", minWidth: 150, flex: 1 },
    { field: "amount", headerName: "Vrednost", minWidth: 150, flex: 1 },
    {
        field: "options",
        headerName: "Možnosti",
        minWidth: 180,
        flex: 1,
        renderCell: (params: any) => {
            return (
                <>
                    <IconButton aria-label="edit" size="small" onClick={() => handleEditClick(params.id)}>
                        <EditIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton aria-label="delete" size="small" onClick={() => handleDeleteClick(params.id)}>
                        <DeleteIcon fontSize="inherit" />
                    </IconButton>
                </>
            );
        },
    },
  ];

  const handleAddUserClick = () => {
    setEditingAddition(null);
    setAddOrEditMode("add");
  };

  const handleEditClick = (id: string) => {
    let docId = "";
    const foundAddition = value?.docs.find((doc) => {
        const match = doc.id === id;
        if (match) docId = doc.id;
        return match;
    })?.data() as MonthlyAddition;
    foundAddition.id = docId;

    if (foundAddition) {
      setEditingAddition(foundAddition);
      setAddOrEditMode("edit");
    }
  };

  const handleDeleteClick = async (id: string) => {
    const shouldDelete = window.confirm(
      "Ali ste prepričani, da želite izbrisati dodatek?"
    );

    if (shouldDelete) {
      const res = await deleteDoc(
        doc(getFirestore(firebaseClient), "monthly_pay_additions", id)
      );
    }
  };

  const createAddition = async (addition: MonthlyAddition) => {
    if (!addition) {
      setAddOrEditMode(null);
      return;
    }
   
    const res = await addDoc(
      collection(getFirestore(firebaseClient), "monthly_pay_additions"),
      addition
    );

    setAddOrEditMode(null);
  };

  const updateAddition = async (addition: MonthlyAddition) => {
    if (!addition) {
      setAddOrEditMode(null);
      return;
    }
    console.log({addition});
    const getAddition = doc(getFirestore(firebaseClient), "monthly_pay_additions", addition?.id ?? '');

    const res = await updateDoc(getAddition, { ...addition });
    setAddOrEditMode(null);
  };


  return (
    <div className="h-[80vh] w-[1400px] max-w-full p-4 m-auto">
      <div className="mb-6 flex justify-between items-center">
        <h1 className="text-3xl">
          Mesecni dodatki
          <IconButton
            aria-label="add"
            size="small"
            onClick={handleAddUserClick}
          >
            <AddIcon fontSize="inherit" />
          </IconButton>

        </h1>
        {/* <button disabled={(selectedUsers?.length ?? 0) <= 0} className="underline disabled:text-gray-400" onClick={() => setSendNotificationDialogVisible(true)}>
          Pošlji obvestilo izbranim uporabnikom
        </button> */}
      </div>
      {addOrEditMode && (
        <AddEditComponent
          mode={addOrEditMode}
          onSave={addOrEditMode == "edit" ? updateAddition : createAddition}
          editingAddition={editingAddition}
		      categories={categories}
        />
      )}
      {fbUsers && (
        <DataGrid
          localeText={tableTranslations}
          rows={fbUsers}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[5]}
          checkboxSelection
        //   onSelectionModelChange={(e) => setSelectedUsers(e)}
        />
      )}
    </div>
  );
}

export interface MonthlyAddition {
  id?: string;
  name?: string;
  user?: string;
  amount?: number;
}
