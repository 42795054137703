import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useEffect, useState } from "react";
import firebaseClient from "../../services/firebaseClient";
import { useAuthState } from "react-firebase-hooks/auth";
import { Alert, Button, TextField } from "@mui/material";
import { redirect, useNavigate } from "react-router-dom";
import { log } from "console";

const auth = getAuth(firebaseClient);

export default function PrijavaPage() {
	// auth check
	const navigate = useNavigate();
	const [user, userLoading, userError] = useAuthState(auth);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loginError, setloginError] = useState(false);

	// if logged in, redirect to app
	useEffect(() => {
		if (user) {
			navigate("/uporabniki");
		}
	}, [user]);

	// on login click
	const handleLogin = () => {
		signInWithEmailAndPassword(auth, email, password)
			.then((res) => {
				setloginError(false);
				navigate("/uporabniki");
			})
			.catch((err) => {
				console.log(err);
				setloginError(true);
			});
	};

	return (
		<div className="flex justify-center items-center mt-24">
			<div className="flex flex-col gap-3 w-[350px] max-w-full p-4">
				<h2 className="mb-6 text-lg">Prijava</h2>
				<TextField label="Email" type="email" onChange={(e) => setEmail(e.target.value)} />
				<TextField label="Geslo" type="password" onChange={(e) => setPassword(e.target.value)} />
				{loginError && <Alert severity="error">Napaka pri prijavi.</Alert>}
				<Button variant="contained" onClick={handleLogin}>
					Prijava
				</Button>
			</div>
		</div>
	);
}
