import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { collection, getFirestore } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { Uporabnik } from ".";
import firebaseClient from "../../services/firebaseClient";
import { BuildSite } from "../BuildSitesPage";
import { Company } from "../CompaniesPage";

interface Props {
  mode: "add" | "edit";
  onSave: ({}: any) => void;
  editingUser?: Uporabnik | null;
  categories: any;
  companies: Company[];
  buildsites: BuildSite[];
}

export default function AddEditComponent(props: Props) {
  const [user, setUser] = useState({} as Uporabnik);

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    setUser(props.editingUser ?? ({} as Uporabnik));
    window.scrollTo(0, 0);
  }, [props.editingUser]);

  const handleSave = (e: any) => {
    e.preventDefault();
    props.onSave(user);
  };

  const handleCancel = () => {
    props.onSave(null);
  };

  return (
    <>
      {(user.id || props.editingUser == null) && <div className="mt-4 mb-8 p-4 shadow-md bg-gray-50 border-gray-200 border">
        <form onSubmit={handleSave} ref={formRef}>
          <h2>{props.mode == "add" ? "Dodaj" : "Uredi"} uporabnika</h2>
          <div className="form flex flex-col gap-4 my-4">
            <TextField
              required={true}
              label="Ime in priimek"
              aria-valuemin={2}
              type="text"
              value={user.name ?? ""}
              onChange={(e) => setUser({ ...user, name: e.target.value })}
            />
            <TextField
              required={true}
              label="Email"
              type="email"
              value={user.email ?? ""}
              onChange={(e) => setUser({ ...user, email: e.target.value })}
            />
            <TextField
              label="Urna postavka"
              required={true}
              type="number"
              inputProps={{step: '0.5'}}
              value={user.hourly_rate ?? 0}
              onChange={(e) => setUser({ ...user, hourly_rate: parseFloat(e.target.value) })}
            />
            <TextField
              label="Opomba"
              type="text"
              value={user.comment ?? ''}
              onChange={(e) => setUser({ ...user, comment: e.target.value })}
            />
            <Autocomplete
              disablePortal
              options={props.companies} 
              getOptionLabel={(option) => option.name ?? '/'}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Delovno mesto" />}
              onChange={(e, selected) => setUser({ ...user, company_id: selected?.id })}
              // defaultValue={props.companies.find((c) => c.id == user.company_id) ?? null}
              value={props.companies.find((c) => c.id == user.company_id)}
            />
            <Autocomplete
              disablePortal
              options={props.buildsites} 
              getOptionLabel={(option) => option.name ?? '/'}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Delovišče" />}
              onChange={(e, selected) => setUser({ ...user, buildsite_id: selected?.id })}
              value={props.buildsites.find((c) => c.id == user.buildsite_id)}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={user.active ?? false}
                  onChange={(e) => setUser({ ...user, active: e.target.checked })}
                />
              }
              label="Aktiven"
            />
          </div>

          <div className="flex flex-row gap-4">
            <Button variant="contained" type="submit">
              Shrani
            </Button>
            <Button variant="contained" onClick={handleCancel}>
              Prekliči
            </Button>
          </div>
        </form>
      </div>}
    </>
  );
}
