import { useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import firebaseClient from "../../services/firebaseClient";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import AddEditComponent from "./AddEditComponent";
import { deleteUser, getAuth } from "firebase/auth";
import { tableTranslations } from "../../misc/localizations";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { CSVLink } from "react-csv";
import { Company } from "../CompaniesPage";
import { BuildSite } from "../BuildSitesPage";

const auth = getAuth(firebaseClient);

export default function UporabnikiPage() {
  // auth check
  const navigate = useNavigate();
  const [user, userLoading, userError] = useAuthState(auth);
  if (!user) navigate("/prijava");

  const [categoriesDocs, categoriesLoading, categoriesErr] = useCollection(
    collection(getFirestore(firebaseClient), "user_categories")
  );

  const [companiesDocs] = useCollection(
    collection(getFirestore(firebaseClient), "companies")
  );

  const companies: Company[] = useMemo(() => {
    if (!companiesDocs) return [];

    return companiesDocs.docs.map((doc) => {
      return {
        id: doc.id,
        name: doc.data().name,
        location: doc.data().location,
        note: doc.data().note,
      } as Company;
    });
  }, [companiesDocs]);

  const [buildSitesDocs] = useCollection(
    collection(getFirestore(firebaseClient), "buildsites")
  );

  const buildsites: Company[] = useMemo(() => {
    if (!buildSitesDocs) return [];

    return buildSitesDocs.docs.map((doc) => {
      return {
        id: doc.id,
        name: doc.data().name,
        location: doc.data().location,
        note: doc.data().note,
      } as Company;
    });
  }, [buildSitesDocs]);

  const categories = categoriesDocs?.docs.map(cat => cat.data().name);


  const [profiles, loading, error] = useCollection(
    collection(getFirestore(firebaseClient), "profiles")
  );
  const [addOrEditMode, setAddOrEditMode] = useState<"add" | "edit" | null>(null);
  const [editingUser, setEditingUser] = useState<Uporabnik | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<any>();

  // custom notification
  const [sendNotificationDialogVisible, setSendNotificationDialogVisible] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");

  // these are users stored in firestore, not from firebase auth
  const fbUsers: Uporabnik[] = useMemo(() => {
    if (!profiles || error) return [];


    return profiles?.docs.map((profileDoc) => {
      const _profile = profileDoc.data();

      var company_name = "/";
      if (_profile.company_id) {
         var _foundCompany: Company | undefined = companies.find(
          (comp) => comp.id == _profile.company_id
        );
        if (_foundCompany) company_name = _foundCompany.name ?? '/';
      }

      var buildsite_name = "/";
      if (_profile.buildsite_id) {
         var _foundBuildSite: BuildSite | undefined = buildsites.find(
          (site) => site.id == _profile.buildsite_id
        );
        if (_foundBuildSite) buildsite_name = _foundBuildSite.name ?? '/';
      }

      return {
        id: profileDoc.id,
        name: _profile.name,
        email: _profile.email,
        active: _profile.active,
        hourly_rate: _profile.hourly_rate,
        comment: _profile.comment,
        company_name: company_name,
        company_id: _profile.company_id,
        buildsite_name: buildsite_name,
        buildsite_id: _profile.buildsite_id,
      } as Uporabnik;
    });
  }, [profiles]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", minWidth: 220, flex: 1 },
    { field: "name", headerName: "Ime", minWidth: 150, flex: 1 },
    { field: "email", headerName: "Email", minWidth: 150, flex: 1 },
    { field: "hourly_rate", headerName: "Urna postavka", minWidth: 150, flex: 1 },
    { field: "company_name", headerName: "Podjetje", minWidth: 150, flex: 1 },
    { field: "buildsite_name", headerName: "Gradbišče", minWidth: 150, flex: 1 },
    { field: "comment", headerName: "Opomba", minWidth: 150, flex: 1, renderCell: (params: any) => <Tooltip title={params.value}><p style={{overflow: "hidden", textOverflow: "ellipsis"}}>{params.value}</p></Tooltip> },
    {
      field: "active",
      headerName: "Aktiven",
      type: "checkbox",
      minWidth: 75,
      flex: 1,
      renderCell: (params: any) => (params.value ? "Da" : "Ne"),
    },
    {
      field: "options",
      headerName: "Možnosti",
      minWidth: 100,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <>
            <IconButton
              aria-label="edit"
              size="small"
              onClick={() => handleEditClick(params.id)}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => handleDeleteClick(params.id, params.row.email)}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </>
        );
      },
    },
  ];

  const handleAddUserClick = () => {
    console.log(addOrEditMode);
    setEditingUser(null);
    setAddOrEditMode("add");
  };

  const handleEditClick = (id: string) => {
    const foundUser = fbUsers.find((u) => u.id === id);

    if (foundUser) {
      setEditingUser(foundUser);
      setAddOrEditMode("edit");
    }
  };

  const handleDeleteClick = async (id: string, email: string) => {
    const shouldDelete = window.confirm(
      "Ali ste prepričani, da želite izbrisati uporabnika?"
    );

    if (shouldDelete) {
      const res = await deleteDoc(
        doc(getFirestore(firebaseClient), "profiles", id)
      );

      const resDeleteAuthUser = await fetch('https://us-central1-aplikacija-c075c.cloudfunctions.net/deleteAuthUser?email=' + email);
    }
  };

  const createUser = async (user: Uporabnik) => {
    if (!user) {
      setAddOrEditMode(null);
      return;
    }

    const usrNoId = user;
    usrNoId.hourly_rate = usrNoId.hourly_rate ?? 0;
    usrNoId.comment = usrNoId.comment ?? '';
    delete usrNoId.id;
    const res = await addDoc(
      collection(getFirestore(firebaseClient), "profiles"),
      usrNoId
    );
   

    setAddOrEditMode(null);
  };

  const updateUser = async (user: Uporabnik) => {
    if (!user?.id) {
      setAddOrEditMode(null);
      return;
    }
    const getUser = doc(getFirestore(firebaseClient), "profiles", user.id!);

    const res = await updateDoc(getUser, { ...user, hourly_rate: user.hourly_rate ?? 0, comment: user.comment ?? "", buildsite_id: user.buildsite_id ?? '', company_id: user.company_id ?? '' });
    setAddOrEditMode(null);
  };

  const handleSendNotificationClick = async () => {
    const data = new URLSearchParams({
		users: selectedUsers,
      	title: notificationTitle,
     	message: notificationMessage
    });
    const res = await fetch(
      "https://us-central1-as-medic.cloudfunctions.net/sendCustomNotificationToSelectedUsers?" +
        data
    );

	setSendNotificationDialogVisible(false)
  };


  return (
    <div className="h-[80vh] w-[1400px] max-w-full p-4 m-auto">
      <Dialog
        open={sendNotificationDialogVisible}
        onClose={() => setSendNotificationDialogVisible(false)}
      >
        <DialogTitle>Pošlji obvestilo</DialogTitle>
        <DialogContent>
          <div className="py-4 flex flex-col gap-4 w-[450px]">
            <TextField
              id="outlined-multiline-static"
              label="Naslov"
              fullWidth
              rows={4}
              onChange={(e) => setNotificationTitle(e.target.value)}
            />
            <TextField
              id="outlined-multiline-static"
              label="Vsebina"
              multiline
              fullWidth
              rows={4}
              onChange={(e) => setNotificationMessage(e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSendNotificationClick}>Pošlji</Button>
        </DialogActions>
      </Dialog>

      <div className="mb-6 flex justify-between items-center">
        <h1 className="text-3xl">
          Uporabniki
          <IconButton
            aria-label="add"
            size="small"
            onClick={handleAddUserClick}
          >
            <AddIcon fontSize="inherit" />
          </IconButton>

        </h1>
        {/* <button disabled={(selectedUsers?.length ?? 0) <= 0} className="underline disabled:text-gray-400" onClick={() => setSendNotificationDialogVisible(true)}>
          Pošlji obvestilo izbranim uporabnikom
        </button> */}
      </div>
      {addOrEditMode && (
        <AddEditComponent
          mode={addOrEditMode}
          onSave={addOrEditMode == "edit" ? updateUser : createUser}
          editingUser={editingUser}
		      categories={categories}
          companies={companies}
          buildsites={buildsites}
        />
      )}
      {fbUsers && (
        <DataGrid
          localeText={tableTranslations}
          rows={fbUsers}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[5]}
          checkboxSelection
          onSelectionModelChange={(e) => setSelectedUsers(e)}
        />
      )}

    </div>
  );
}

export interface Uporabnik {
  id?: string;
  name?: string;
  email?: string;
  active?: boolean;
  hourly_rate?: number;
  comment?: string;
  company_id? : string;
  buildsite_id?: string;
  //category?: string;
}
