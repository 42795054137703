import {
  Autocomplete,
    Button,
    Checkbox,
    FormControlLabel,
    TextField,
  } from "@mui/material";
import { collection, getFirestore } from "firebase/firestore";
  import { useEffect, useMemo, useRef, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { MonthlyAddition } from ".";
import firebaseClient from "../../services/firebaseClient";
  
  interface Props {
    mode: "add" | "edit";
    onSave: ({}: any) => void;
    editingAddition?: MonthlyAddition | null;
    categories: any;
  }
  
  export default function AddEditComponent(props: Props) {
    const [addition, setAddition] = useState({} as MonthlyAddition);
  
    const formRef = useRef<HTMLFormElement>(null);

    const [users] = useCollection(
      collection(getFirestore(firebaseClient), "profiles")
    );

    // users that have availability for this event + are active
    const filteredUsers = useMemo(() => {
      let filtered: any[] = [];
      users?.docs?.forEach((usrData) => {
        const user = usrData.data();
        if (user.active) {
          filtered.push(user);
        }
      });
      return filtered;
    }, [users]);
  
    useEffect(() => {
      setAddition(props.editingAddition ?? ({} as MonthlyAddition));
      window.scrollTo(0, 0);
    }, [props.editingAddition]);
  
    const handleSave = (e: any) => {
      e.preventDefault();
      props.onSave({...addition});
    };
  
    const handleCancel = () => {
      props.onSave(null);
    };

    const [localAmount, setLocalAmount] = useState('0');

    useEffect(() => {
      setLocalAmount(props.editingAddition?.amount?.toString() ?? '0');
    }, [props.editingAddition])
    

    const handleAmountChange = (e: any) => {
      const val = e.target.value;
          const parsed = parseFloat(val);
          if (!isNaN(parsed)) {
            setAddition({ ...addition, amount: parsed })
            setLocalAmount(val);
          }
          else setLocalAmount(val);
    }
  
    return (
      <div className="mt-4 mb-8 p-4 shadow-md bg-gray-50 border-gray-200 border">
        <form onSubmit={handleSave} ref={formRef}>
          <h2>{props.mode == "add" ? "Dodaj" : "Uredi"} dodatek </h2>
          <div className="form flex flex-col gap-4 my-4">
            {props.mode == 'edit' && <p style={{marginBottom: '8px'}}><strong>email: </strong>{props.editingAddition?.user}</p>}
            <TextField
              required={true}
              label="Ime"
              aria-valuemin={2}
              type="text"
              value={addition.name ?? ""}
              onChange={(e) => setAddition({ ...addition, name: e.target.value })}
            />
            {props.mode == 'add' ? <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={filteredUsers}
              getOptionLabel={(option) => option.name + " (" + option.email + ")"}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Uporabnik" />}
              onChange={(e, selected) => setAddition({ ...addition, user: selected.email })}
              defaultValue={filteredUsers.find((usr) => usr.email == addition.user)}
              />
              : <span></span>}
            <TextField
              required={true}
              label="Vrednost"
              type="number"
              value={localAmount}
              onChange={(e) => handleAmountChange(e)}
            />
          </div>
  
          <div className="flex flex-row gap-4">
            <Button variant="contained" type="submit">
              Shrani
            </Button>
            <Button variant="contained" onClick={handleCancel}>
              Prekliči
            </Button>
          </div>
        </form>
      </div>
    );
  }
  