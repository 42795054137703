import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCtPHitvqUgxPYhqh64H1zyg9Z1VRhK0PE",
  authDomain: "aplikacija-c075c.firebaseapp.com",
  projectId: "aplikacija-c075c",
  storageBucket: "aplikacija-c075c.appspot.com",
  messagingSenderId: "130637953745",
  appId: "1:130637953745:web:7c2c985e72cb4e10d823f0",
  measurementId: "G-4HTVHJEJ1G"
};
export default initializeApp(firebaseConfig);