import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { collection, getFirestore, Timestamp } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import firebaseClient from "../../services/firebaseClient";
import { TimeTracking } from "../DogodkiPage/NotificationComponent";

interface Props {
	mode: "add" | "edit";
	onSave: any;
	editingTimetracking?: TimeTracking | null;
	onClose: any;
}

export default function AddEditComponent(props: Props) {
	const [timetracking, setTimetracking] = useState({} as TimeTracking);

	const formRef = useRef<HTMLFormElement>(null);

	useEffect(() => {
		setTimetracking(props.editingTimetracking ?? ({} as TimeTracking));
		window.scrollTo(0, 0);
	}, [props.editingTimetracking]);

	const handleSave = (e: any) => {
		e.preventDefault();
		props.onSave(timetracking);
	};

	const handleCancel = () => {
		props.onSave(null);
	};

	return (
		<div className="mt-4 mb-8 p-4 shadow-md bg-gray-50 border-gray-200 border">
			<form onSubmit={handleSave} ref={formRef}>
				<h2>{props.mode == "add" ? "Dodaj" : "Uredi"} ure</h2>
				<p style={{ marginTop: '8px', marginBottom: "4px" }}><strong>email: </strong>{props.editingTimetracking?.email}</p>
				<div className="form flex flex-col gap-4 my-4">
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DateTimePicker
							ampm={false}
							inputFormat="DD.MM.YYYY HH:mm"
							label="Datum"
							value={timetracking.timestamp ? moment(timetracking.timestamp?.toDate()) : null}
							onChange={(val) => val && setTimetracking({ ...timetracking, timestamp: Timestamp.fromDate(val!.toDate()) })}
							renderInput={(params) => <TextField {...params} />}
						/>
					</LocalizationProvider>
					<TextField
						label="Stevilo ur"
						type="number"
						value={timetracking.entry_value ?? 0}
						inputProps={{step: "0.5", lang: "nl-NL"}}
						onChange={(e) => setTimetracking({ ...timetracking, entry_value: parseFloat(e.target.value) })}
					/>
					{/* <TextField
						label="Urna postavka"
						required={true}
						type="number"
						inputProps={{step: '0.5'}}
						value={timetracking.entry_value ?? 0}
						onChange={(e) => setUser({ ...user, hourly_rate: parseFloat(e.target.value) })}
					/> */}
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={timetracking.entry_type ?? ''}
						label="Age"
						onChange={(e) => setTimetracking({ ...timetracking, entry_type: e.target.value as string })}
					>
						<MenuItem value='working_day'>Delovni dan</MenuItem>
						<MenuItem value='praznik'>Praznik</MenuItem>
						<MenuItem value='dopust'>Dopust</MenuItem>
						<MenuItem value='prost_dan'>Prost dan</MenuItem>
					</Select>
				</div>

				<div className="flex flex-row gap-4">
					<Button variant="contained" type="submit">
						Shrani
					</Button>
					<Button variant="contained" onClick={handleCancel}>
						Prekliči
					</Button>
				</div>
			</form>
		</div>
	);
}
