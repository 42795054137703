import { useMemo, useRef, useState } from "react";
import {
  Button,
  IconButton, TextField,
} from "@mui/material";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  addDoc,
  collection,
  getFirestore,
  orderBy,
  query,
} from "firebase/firestore";
import firebaseClient from "../../services/firebaseClient";
import { DataGrid, GridColDef, gridFilteredSortedRowIdsSelector, GridToolbarContainer, GridToolbarExport, GridValidRowModel, gridVisibleSortedRowEntriesSelector, gridVisibleSortedRowIdsSelector } from "@mui/x-data-grid";
import { getAuth } from "firebase/auth";
import { tableTranslations } from "../../misc/localizations";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CSVLink } from "react-csv";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import jsPDF from "jspdf";

const auth = getAuth(firebaseClient);

export default function ObracunPlacePage() {
  const [sortedRows, setSortedRows] = useState<GridValidRowModel[]>([]);

  // auth check
  const navigate = useNavigate();
  const [user, userLoading, userError] = useAuthState(auth);
  if (!user) navigate("/prijava");

  const [selectedFilterDate, setSelectedFilterDate] = useState<any | null>(new Date());

  const [categoriesDocs, categoriesLoading, categoriesErr] = useCollection(
    collection(getFirestore(firebaseClient), "user_categories")
  );
  const categories = categoriesDocs?.docs.map(cat => cat.data().name);

  const [companiesDocs, companiesLoading, companiesErr] = useCollection(
    collection(getFirestore(firebaseClient), "companies")
  );

  const [additions, loading, error] = useCollection(
    collection(getFirestore(firebaseClient), "monthly_pay_additions")
  );

  // const [users] = useCollection(
  //   collection(getFirestore(firebaseClient), "profiles")
  // );

  const [users] = useCollection(
    query(
      collection(getFirestore(firebaseClient), "profiles"),
      orderBy("name", "asc")
    )
  );



  const [timetracking] = useCollection(
    collection(getFirestore(firebaseClient), "timetracking")
  );

  const [addOrEditMode, setAddOrEditMode] = useState<"add" | "edit" | null>(null);
  const [editingAddition, setEditingAddition] = useState<ObracunPlace | null>(null);

  // these are users stored in firestore, not from firebase auth
  const rows: ObracunPlace[] = useMemo(() => {
    if (!additions || !users) return [];

    return users?.docs.map((userDoc) => {
      const userData = userDoc.data();
      const foundCompany = companiesDocs?.docs.find(companyDoc => companyDoc.id === userData.company_id)?.data();

      // additions for selected month
      let additionsTotal = 0;
      let subtractionsTotal = 0;
      additions?.docs.forEach((additionDoc) => {
        const additionData = additionDoc.data();
        if (additionData.user === userData.email) {
          if (additionData.amount >= 0) additionsTotal += additionData.amount;
          else subtractionsTotal += additionData.amount;
        }
      });

      // hourly pay total for selected month
      let hourlyPayTotal = 0;
      timetracking?.docs.forEach((timetrackingDoc) => {
        const timetrackingData = timetrackingDoc.data();
        if (timetrackingData.email === userData.email && timetrackingData.timestamp.toDate().getMonth() === selectedFilterDate?.getMonth() && timetrackingData.timestamp.toDate().getFullYear() === selectedFilterDate?.getFullYear()) {
          hourlyPayTotal += timetrackingData.entry_value * userData.hourly_rate;
        }
      });

      return {
        id: userDoc.id,
        name: userData.name,
        user: userData.user,
        additions_total: additionsTotal,
        subtractions_total: subtractionsTotal,
        final_pay_hours: hourlyPayTotal ?? 0,
        final_pay_sum: (hourlyPayTotal + additionsTotal + subtractionsTotal) ?? 0,
        hourly_rate: userData.hourly_rate ?? 0,
        hours_worked: hourlyPayTotal && userData.hourly_rate ? hourlyPayTotal / userData.hourly_rate : 0,
        company_name: foundCompany != undefined ? foundCompany['name']  : '/',
      };
    });
  }, [additions, users, selectedFilterDate]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", minWidth: 160, flex: 1 },
    { field: "name", headerName: "Ime", minWidth: 140, flex: 1 },
    { field: "hours_worked", headerName: "Ure", minWidth: 140, flex: 1 },
    { field: "hourly_rate", headerName: "Urna postavka", minWidth: 140, flex: 1 },
    { field: "final_pay_hours", headerName: "Osnovno placilo", minWidth: 140, flex: 1 },
    { field: "additions_total", headerName: "Skupno za pristet", minWidth: 140, flex: 1 },
    { field: "subtractions_total", headerName: "Skupno za odstet", minWidth: 150, flex: 1 },
    { field: "final_pay_sum", headerName: "Koncno placilo", minWidth: 150, flex: 1 },
    { field: "company_name", headerName: "Podjetje", minWidth: 120, flex: 1 },
  ];

  const handleAddUserClick = () => {
    setEditingAddition(null);
    setAddOrEditMode("add");
  };

  const createAddition = async (addition: ObracunPlace) => {
    if (!addition) {
      setAddOrEditMode(null);
      return;
    }

    const res = await addDoc(
      collection(getFirestore(firebaseClient), "monthly_pay_additions"),
      addition
    );

    setAddOrEditMode(null);
  };

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Porocilo ur" + (selectedFilterDate ? " za " + selectedFilterDate?.toLocaleString("sl-SI", { month: "long" }) + " " + selectedFilterDate?.getFullYear() : "");
    const headers = [["Ime in priimek", "Ure", "Urna postavka", "Placilo (brez dodatkov)", "Pristeti", 'Odsteti', 'Koncno placilo']];

    const data = sortedRows.map((elt: any) => [
      elt.name,
      elt.hours_worked,
      elt.hourly_rate,
      elt.final_pay_hours,
      elt.additions_total,
      elt.subtractions_total,
      elt.final_pay_sum,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    // @ts-ignore
    doc.autoTable(content);

    // @ts-ignore
    let finalY = doc.autoTable.previous.finalY;
    doc.setFontSize(12);

    const tmpUser = rows[0];

    const filename = 'Obracun_place.pdf'
    doc.save(filename);
  };

  const csvFileTitle = 'Obracun_place.csv';

  const csvData = useMemo(() => {
    const headers = ["Ime in priimek", "Ure", "Urna postavka", "Placilo (brez dodatkov)", "Pristeti", 'Odsteti', 'Koncno placilo'];

    const data = sortedRows.map((elt: any) => [
      elt.name,
      elt.hours_worked,
      elt.hourly_rate,
      elt.final_pay_hours,
      elt.additions_total,
      elt.subtractions_total,
      elt.final_pay_sum,
    ]);

    return [headers, ...data];
  }, [rows, sortedRows]);


  return (
    <div className="h-[80vh] w-[1400px] max-w-full p-4 m-auto">
      <div className="mb-6 flex justify-between items-center">
        <h1 className="text-3xl">
          Obracun place
        </h1>
        {/* <button disabled={(selectedUsers?.length ?? 0) <= 0} className="underline disabled:text-gray-400" onClick={() => setSendNotificationDialogVisible(true)}>
          Pošlji obvestilo izbranim uporabnikom
        </button> */}
      </div>
      <div className="flex justify-between mb-4 items-end">
        <div className="flex items-center">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              views={["month", "year"]}
              label="Filter"
              minDate={new Date("2012-03-01")}
              maxDate={new Date("2030-06-01")}
              value={selectedFilterDate}
              onChange={(val) => {
                setSelectedFilterDate(new Date(val?.toString() ?? ''))
              }}
              renderInput={(params: any) => (
                <TextField {...params} helperText={null} />
              )}
            />
          </LocalizationProvider>
        </div>

        <div className="flex gap-4 h-8">
          <Button variant="contained" color="info" onClick={exportPDF}>
            PDF
          </Button>
          <Button variant="contained" color="info">
            <CSVLink filename={csvFileTitle} data={csvData}>CSV</CSVLink>
          </Button>
        </div>
      </div>
      {rows && (
        <DataGrid
          localeText={tableTranslations}
          onStateChange={(state) => {
            const newRows = gridVisibleSortedRowEntriesSelector(state);
            const final = newRows.map((row) => row.model);
            setSortedRows(final);
          }}
          rows={rows}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[5]}
          checkboxSelection
          // gridFilteredSortedRowIdsSelector={() => {}}
        //   onSelectionModelChange={(e) => setSelectedUsers(e)}
        />
      )}
    </div>
  );
}

export interface ObracunPlace {
  name?: string;
  hours?: string;
  hourly_rate?: number;
  additions_total?: number;
}
