import { useMemo, useRef, useState } from "react";
import { Button, IconButton, TextField } from "@mui/material";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, getFirestore, query, Timestamp, where } from "firebase/firestore";
import firebaseClient from "../../services/firebaseClient";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { getAuth } from "firebase/auth";
import { tableTranslations } from "../../misc/localizations";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { TimeTracking } from "../DogodkiPage/NotificationComponent";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const auth = getAuth(firebaseClient);

export default function TimeTrackingInfoPage() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { email } = state;

    // auth check
    const [user, userLoading, userError] = useAuthState(auth);
    if (!user) navigate("/prijava");

    const [value, loading, error] = useCollection(query(collection(getFirestore(firebaseClient), "timetracking"), where("email", "==", email)));
    const [valConfirmedHours] = useCollection(query(collection(getFirestore(firebaseClient), "confirmed_hours"), where("email", "==", email)));

    const [users] = useCollection(collection(getFirestore(firebaseClient), "profiles"));

    const [selectedFilterDate, setSelectedFilterDate] = useState<any | null>(null);

    const rows: TimeTracking[] = useMemo(() => {
        if (!value || error) return [];

        let timeData = value?.docs.sort((a, b) => (a.data().timestamp > b.data().timestamp ? 1 : -1)).map((doc) => {
            const docData = doc.data();

            let user;
            users?.docs.map((doc) => {
                const dataUser = doc.data();
                if (dataUser.email == docData.email) {
                    user = dataUser.name;
                }
            });

            // check if confirmed
			let confirmed = false;
			valConfirmedHours?.docs.map((confirmedDoc) => {
				const dataConfirmed = confirmedDoc.data();
				if (dataConfirmed.month.toDate().getMonth() == docData.timestamp.toDate().getMonth() && dataConfirmed.month.toDate().getFullYear() == docData.timestamp.toDate().getFullYear() && dataConfirmed.email == docData.email) {
					confirmed = true;
				}
			});

            return {
                id: doc.id,
                name: user,
                email: docData.email,
                entry_type: docData.entry_type,
                entry_value: docData.entry_value,
                timestamp: docData.timestamp,
                confirmed: confirmed
            } as TimeTracking;
        })

        timeData = timeData.filter((dt) => {
            if (selectedFilterDate == null) return true;
            return dt.timestamp.toDate().getFullYear() == selectedFilterDate?.getFullYear() && dt.timestamp.toDate().getMonth() == selectedFilterDate?.getMonth();
        });

        return timeData;
    }, [value, selectedFilterDate]);

    const pageUser = useMemo(() => {
        if ((users?.docs.length ?? 0) > 0) {
            const found = users?.docs.find((doc) => doc.data().email == email);

            return found?.data();
        }
    }, [users, value])
    const sumHours = useMemo(() => rows?.reduce((prev: any, newVal: any) => prev + newVal.entry_value, 0) ?? 0, [rows]);
    const totalPay = useMemo(() => sumHours * (pageUser?.hourly_rate ?? 0), [sumHours, pageUser])

    const columns: GridColDef[] = [
        { field: "id", headerName: "ID", minWidth: 110, flex: 1 },
        { field: "name", headerName: "Uporabnik", minWidth: 200, flex: 1 },
        { field: "email", headerName: "Email", minWidth: 200, flex: 1 },
        {
            field: "timestamp",
            headerName: "Datum",
            minWidth: 160,
            flex: 1,
            renderCell: (params: any) => {
                return <p>{params?.value?.toDate()?.toLocaleString() ?? "/"}</p>;
            },
        },
        { field: "entry_value", headerName: "Število ur", minWidth: 50, flex: 1 },
        {
            field: "entry_type",
            headerName: "Vrsta",
            minWidth: 160,
            flex: 1,
            renderCell: (params: any) => {
                return (
                    <p>
                        {params?.value == "dopust"
                            ? "Dopust"
                            : params?.value == "praznik"
                                ? "Praznik"
                                : params?.value == "prost_dan"
                                    ? "Prosto"
                                    : params?.value == "working_day"
                                        ? "Delovni dan"
                                        : params?.value ?? ""}
                    </p>
                );
            },
        },
        {
			field: "confirmed",
			headerName: "Potrjen",
			minWidth: 100,
			flex: 1,
			renderCell: (params: any) => {
				return <p>{params?.value ? 'DA' : 'NE'}</p>
			},
		},
    ];

    const exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Porocilo ur" + (selectedFilterDate ? " za " + selectedFilterDate?.toLocaleString("sl-SI", { month: "long" }) + " " + selectedFilterDate?.getFullYear() : "");
        const headers = [["DATUM", "IME", "EMAIL", "STEVILO UR", "VRSTA", 'POTRJEN']];

        const data = rows.map((elt: any) => [
            elt.timestamp.toDate()?.toLocaleString(),
            elt.name,
            elt.email,
            elt.entry_value,
            elt.entry_type == "dopust"
                ? "Dopust"
                : elt.entry_type == "praznik"
                    ? "Praznik"
                    : elt.entry_type == "prost_dan"
                        ? "Prosto"
                        : elt.entry_type == "working_day"
                            ? "Delovni dan"
                            : elt.entry_type,
            elt.confirmed ? 'DA' : 'NE'
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data,
        };
        var sumString = "Skupno stevilo ur: " + sumHours;
        var pay = "Skupna placa (neto): " + totalPay + "€";

        doc.text(title, marginLeft, 40);
        // @ts-ignore
        doc.autoTable(content);

        // @ts-ignore
        let finalY = doc.autoTable.previous.finalY;
        doc.setFontSize(12);
        doc.text(sumString, marginLeft, finalY + 20);
        doc.text(pay, marginLeft, finalY + 40);
        
        const tmpUser = rows[0];

        const filename = selectedFilterDate ? tmpUser?.name?.replaceAll(' ', '_') + "_ure_" + (parseInt(selectedFilterDate?.getMonth()) + 1) + "_" + selectedFilterDate?.getFullYear() + '.pdf' : tmpUser?.name?.replaceAll(' ', '_') + "_ure_skupno.pdf";
        doc.save(filename);
    };

    const csvFileTitle = rows.length > 0 ? selectedFilterDate ? rows[0]?.name?.replaceAll(' ', '_') + "_ure_" + (parseInt(selectedFilterDate?.getMonth()) + 1) + "_" + selectedFilterDate?.getFullYear() + '.csv' : rows[0]?.name?.replaceAll(' ', '_') + "_ure_skupno.csv" : 'ure_skupno.csv';

    const csvData = useMemo(() => {
        const headers = ["DATUM", "IME", "EMAIL", "STEVILO UR", "VRSTA", 'POTRJEN'];

        const data = rows.map((elt: any) => [
            elt.timestamp.toDate()?.toLocaleString(),
            elt.name,
            elt.email,
            elt.entry_value?.toString(),
            elt.entry_type == "dopust"
                ? "Dopust"
                : elt.entry_type == "praznik"
                    ? "Praznik"
                    : elt.entry_type == "prost_dan"
                        ? "Prosto"
                        : elt.entry_type == "working_day"
                            ? "Delovni dan"
                            : elt.entry_type,
            elt.confirmed ? 'DA' : 'NE'
        ]);

        return [headers, ...data];
    }, [rows]);

    return (
        <div className="h-[80vh] w-[1400px] max-w-full p-4 m-auto">
            <h1 className="mb-6 text-3xl">Beleženje ur</h1>

            <div className="flex justify-between mb-4 items-end">
                <div className="flex items-center">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            views={["month", "year"]}
                            label="Filter"
                            minDate={new Date("2012-03-01")}
                            maxDate={new Date("2030-06-01")}
                            value={selectedFilterDate}
                            onChange={(val) => {
                                setSelectedFilterDate(new Date(val?.toString() ?? ''))
                            }}
                            renderInput={(params: any) => (
                                <TextField {...params} helperText={null} />
                            )}
                        />
                    </LocalizationProvider>
                    <IconButton onClick={() => setSelectedFilterDate(null)}>
                        <RestartAltIcon fontSize="inherit" />
                    </IconButton>
                </div>

                <div className="flex gap-4 h-8">
                    <Button variant="contained" color="info" onClick={exportPDF}>
                        PDF
                    </Button>
                    <Button variant="contained" color="info">
                        <CSVLink filename={csvFileTitle} data={csvData}>CSV</CSVLink>
                    </Button>
                </div>
            </div>

            {rows && (
                <DataGrid
                    localeText={tableTranslations}
                    rows={rows}
                    columns={columns}
                    pageSize={15}
                    rowsPerPageOptions={[5]}
                />
            )}
            <p className="mb-3 text-2xl">{"Skupno število ur: " + sumHours}</p>
            <p className="mb-6 text-2xl">{"Skupna plača (neto): " + totalPay + "€"}</p>
        </div>
    );
}
