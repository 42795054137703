import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { collection, getFirestore, Timestamp } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { Company } from ".";
import firebaseClient from "../../services/firebaseClient";
import { TimeTracking } from "../DogodkiPage/NotificationComponent";

interface Props {
	mode: "add" | "edit";
	onSave: any;
	editingCompany?: Company | null;
	onClose: any;
}

export default function AddEditComponent(props: Props) {
	const [company, setCompany] = useState({} as Company);

	const formRef = useRef<HTMLFormElement>(null);

	useEffect(() => {
		setCompany(props.editingCompany ?? ({} as Company));
		window.scrollTo(0, 0);
	}, [props.editingCompany]);

	const handleSave = (e: any) => {
		e.preventDefault();
		props.onSave(company);
	};

	const handleCancel = () => {
		props.onSave(null);
	};

	return (
		<div className="mt-4 mb-8 p-4 shadow-md bg-gray-50 border-gray-200 border">
			<form onSubmit={handleSave} ref={formRef}>
				<h2>{props.mode == "add" ? "Dodaj" : "Uredi"} podjetje</h2>
				<div className="form flex flex-col gap-4 my-4">
					<TextField
						label="Ime"
						type="text"
						value={company.name ?? ''}
						onChange={(e) => setCompany({ ...company, name: e.target.value })}
					/>
					<TextField
						label="Naslov"
						type="text"
						value={company.address ?? ''}
						onChange={(e) => setCompany({ ...company, address: e.target.value })}
					/>
					<TextField
						label="Opomba"
						type="text"
						value={company.note ?? ''}
						onChange={(e) => setCompany({ ...company, note: e.target.value })}
					/>
					{/* <Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={company.address ?? ''}
						label="Naslov"
						onChange={(e) => setCompany({ ...company, address: e.target.value })}
					>
						<MenuItem value='working_day'>Delovni dan</MenuItem>
						<MenuItem value='praznik'>Praznik</MenuItem>
						<MenuItem value='dopust'>Dopust</MenuItem>
					</Select> */}
				</div>

				<div className="flex flex-row gap-4">
					<Button variant="contained" type="submit">
						Shrani
					</Button>
					<Button variant="contained" onClick={handleCancel}>
						Prekliči
					</Button>
				</div>
			</form>
		</div>
	);
}
