import React, { useEffect, useMemo, useState } from "react";
import { Button, IconButton, TextField } from "@mui/material";
import { useCollection } from "react-firebase-hooks/firestore";
import { addDoc, collection, deleteDoc, doc, getFirestore, query, setDoc, Timestamp, updateDoc, where } from "firebase/firestore";
import firebaseClient from "../../services/firebaseClient";
import { DataGrid, GridColDef, GridValidRowModel, GridValueGetterParams, gridVisibleSortedRowEntriesSelector } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from '@mui/icons-material/Info';
import AddEditComponent from "./AddEditComponent";
import { deleteUser, getAuth, User } from "firebase/auth";
import { tableTranslations } from "../../misc/localizations";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { TimeTracking } from "../DogodkiPage/NotificationComponent";
import firebaseService from "../../services/firebaseService";
import jsPDF from "jspdf";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CSVLink } from "react-csv";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Uporabnik } from "../UporabnikiPage";


const auth = getAuth(firebaseClient);

export default function CompaniesPage() {
	const [sortedRows, setSortedRows] = useState<GridValidRowModel[]>([]);

	// auth check
	const navigate = useNavigate();
	const [user, userLoading, userError] = useAuthState(auth);
	if (!user) navigate("/prijava");

	const [companiesDocs, companiesLoading, companiesError] = useCollection(collection(getFirestore(firebaseClient), "companies"));
	const [timetrackingDocs] = useCollection(collection(getFirestore(firebaseClient), "timetracking"));

	// const [events] = useCollection(collection(getFirestore(firebaseClient), "events"));
	const [users] = useCollection(collection(getFirestore(firebaseClient), "profiles"));

	const [selectedFilterDate, setSelectedFilterDate] = useState<any | null>(null);

	const [addOrEditMode, setAddOrEditMode] = useState<"add" | "edit" | null>(null);
	const [editingObject, setEditingObject] = useState<Company | null>(null);

	const handleEditClick = (id: string) => {
		const found = rows.find((u) => u.id === id);

		if (found) {
			setEditingObject(found);
			setAddOrEditMode("edit");
		}
	};

	const rows: Company[] = useMemo(() => {
		if (!companiesDocs || companiesError) return [];

		const allUserProfiles = users?.docs.map((doc) => doc.data()) as Uporabnik[];
		const allTimeTrackings = timetrackingDocs?.docs.map((doc) => doc.data()) as TimeTracking[];

		let companiesRows = companiesDocs?.docs.map((companyDoc) => {
			const _companyData = companyDoc.data() as Company;
			const _companyUsers = allUserProfiles.filter((user) => (user?.company_id) === companyDoc.id);

			let hrsSum = 0;
			_companyUsers.forEach((user) => {
				const _userTimeTrackings = allTimeTrackings.filter((tt) => tt.email === user.email);

				_userTimeTrackings.forEach((tt) => {
					if (selectedFilterDate) {
						const timetrackingTimestamp = tt.timestamp.toDate();

						if (timetrackingTimestamp.getFullYear() === selectedFilterDate.getFullYear() && timetrackingTimestamp.getMonth() === selectedFilterDate.getMonth()) {
							hrsSum += tt.entry_value ?? 0;
						}
					} else {
						hrsSum += tt.entry_value ?? 0;
					}
				});
			});

			return {
				id: companyDoc.id,
				name: _companyData?.name ?? '/',
				address: _companyData.address ?? '/',
				note: _companyData.note ?? '/',
				num_hours: hrsSum.toFixed(2),
				num_of_employees: _companyUsers.length,
			} as Company;
		});
		return companiesRows;
	}, [companiesDocs, selectedFilterDate, timetrackingDocs]);

	const columns: GridColDef[] = [
		{ field: "id", headerName: "ID", minWidth: 110, flex: 1 },
		{ field: "name", headerName: "Ime", minWidth: 150, flex: 1 },
		{ field: "address", headerName: "Naslov", minWidth: 200, flex: 1 },
		{
			field: "note",
			headerName: "Opomba",
			minWidth: 160,
			flex: 1

		},
		{
			field: "num_hours",
			headerName: "St. ur",
			minWidth: 160,
			flex: 1

		},
		{
			field: "num_of_employees",
			headerName: "St. zaposlenih",
			minWidth: 160,
			flex: 1

		},
		{
			field: "options",
			headerName: "Možnosti",
			minWidth: 100,
			flex: 1,
			renderCell: (params: any) => {
				return (
					<>
						<IconButton
							aria-label="edit"
							size="small"
							onClick={() => handleEditClick(params.id)}
						>
							<EditIcon fontSize="inherit" />
						</IconButton>
						<IconButton
							aria-label="delete"
							size="small"
							onClick={() => handleDeleteClick(params.id)}
						>
							<DeleteIcon fontSize="inherit" />
						</IconButton>
					</>
				);
			},
		},
	];


	const csvData = useMemo(() => {
		const headers = ["IME", "NASLOV", "OPOMBA", "ŠTEVILO UR", "ŠTEVILO ZAPOSLENIH"];

		const data = sortedRows.map((elt: any) => [
			elt.name,
			elt.address,
			elt.note,
			elt.num_hours,
			elt.num_of_employees,
		]);

		return [headers, ...data];
	}, [rows, sortedRows]);

	const exportPDF = () => {
		const unit = "pt";
		const size = "A4"; // Use A1, A2, A3 or A4
		const orientation = "portrait"; // portrait or landscape

		const marginLeft = 40;
		const doc = new jsPDF(orientation, unit, size);

		doc.setFontSize(15);

		const title = "Porocilo ur" + (selectedFilterDate ? " za " + selectedFilterDate?.toLocaleString("sl-SI", { month: "long" }) + " " + selectedFilterDate?.getFullYear() : "");

		const headers = [["IME", "NASLOV", "OPOMBA", "ŠT UR", "ŠT ZAPOSLENIH"]];

		const data = sortedRows.map((elt: any) => [
			elt.name,
			elt.address,
			elt.note,
			elt.num_hours,
			elt.num_of_employees,
		]);

		let content = {
			startY: 50,
			head: headers,
			body: data,
		};

		doc.text(title, marginLeft, 40);
		// @ts-ignore
		doc.autoTable(content);
		doc.setFontSize(12);

		const filename = 'belezenje_ur_podjetja.pdf';
		doc.save(filename);
	};


	const handleDeleteClick = async (id: string) => {
		const shouldDelete = window.confirm("Ali ste prepričani, da želite izbrisati podjetje?");

		if (shouldDelete) {
			const res = await deleteDoc(doc(getFirestore(firebaseClient), "companies", id));
		}
	};

	const updateOrAddCompany = async (companyRes: any) => {
		if (companyRes == null) {
			setAddOrEditMode(null);
			return;
		}

		if (editingObject != null) {
			const getCompanies = doc(getFirestore(firebaseClient), "companies", companyRes.id!);
			const res = await updateDoc(getCompanies, { ...companyRes });
		} else {
			const res = await addDoc(collection(getFirestore(firebaseClient), "companies"), { ...companyRes });
		}

		setAddOrEditMode(null);
	}

	const handleAddClick = () => {
		setEditingObject(null);
		setAddOrEditMode("add");
	};

	return (
		<div className="h-[80vh] w-[1400px] max-w-full p-4 m-auto">
			<h1 className="mb-6 text-3xl">
				Podjetja
				<IconButton aria-label="add" size="small" onClick={handleAddClick}>
					<AddIcon fontSize="inherit" />
				</IconButton>
			</h1>
			{addOrEditMode && <AddEditComponent onSave={updateOrAddCompany} onClose={() => setAddOrEditMode(null)} mode={addOrEditMode} editingCompany={editingObject} />}
			<div className="flex justify-between mb-4 items-end mt-2">
				<div className="flex items-center">
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DatePicker
							views={["month", "year"]}
							label="Filter"
							minDate={new Date("2012-03-01")}
							maxDate={new Date("2030-06-01")}
							value={selectedFilterDate}
							onChange={(val) => {
								setSelectedFilterDate(new Date(val?.toString() ?? ''))
							}}
							renderInput={(params: any) => (
								<TextField {...params} helperText={null} />
							)}
						/>
					</LocalizationProvider>
					<IconButton onClick={() => setSelectedFilterDate(null)}>
						<RestartAltIcon fontSize="inherit" />
					</IconButton>
				</div>

				<div className="flex gap-4 h-8">
					<Button variant="contained" color="info" onClick={exportPDF}>
						PDF
					</Button>
					<Button variant="contained" color="info">
						<CSVLink filename='belezenje_ur_podjetja.csv' data={csvData}>CSV</CSVLink>
					</Button>
				</div>
			</div>
			{rows && <DataGrid localeText={tableTranslations} rows={rows} columns={columns} pageSize={15} rowsPerPageOptions={[5]} onStateChange={(state) => {
				const newRows = gridVisibleSortedRowEntriesSelector(state);
				const final = newRows.map((row) => row.model);
				setSortedRows(final);
			}} />}
		</div>
	);
}


export interface Company {
	id?: string;
	name?: string;
	address?: string;
	note?: string;
	num_of_employees?: number;
}
